<template>
  <div class="gallery">
    <div class="container">
      <div
        data-aos="fade-down"
        class="text-center mb-5 heading with-border aos-init aos-animate"
      >
        <h2 class="text-uppercase text-teal-dark title">Gallery</h2>
      </div>

      <!-- <div class="row justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 mb-3">
          <iframe width="100%" height="300" src="https://www.youtube.com/embed/ucogFDCSJNg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div> -->

      <div class="row justify-content-center">
        <div
          class=""
          v-for="(images, index) in gallery"
          :data-key="index"
          :data-count="images.items.length - 1"
          :key="index"
          :class="images.className"
        >
          <div class="flex-wrap row d-flex justify-content-center">
            <div
              class=""
              v-for="(image, imageIndex) in images.items"
              :key="imageIndex"
              :class="image.class"
            >
              <div class="elevation" :data-aos="image.transition">
                <div class="gallery-item" @click="showPopup(image)">
                  <!-- <v-lazy-image :src=""/> -->
                  <img :src="`${image.th || image.full}&tr=w-650`" lazy />
                  <div class="hover-content">
                    <i class="material-icons">zoom_in</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="row justify-content-center d-md-flex d-none"> -->
      <!-- <div class="col-md-12 col-lg-10 col-xl-8"> -->
      <!-- <iframe width="100%" height="400" src="https://www.youtube.com/embed/rTU7Zv1OiUw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>

    <transition name="fade">
      <div class="popup" v-if="preview">
        <div class="popup-wrapper">
          <div class="popup-tools">
            <button class="popup-close" @click="closePopup">
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="popup-controls">
            <div class="left control" @click="prev">
              <i class="material-icons">chevron_left</i>
            </div>
            <div class="right control" @click="next">
              <i class="material-icons">chevron_right</i>
            </div>
          </div>
          <div class="popup-body">
            <img :src="preview.full" class="img-fluid" />
            <div class="copyright" v-if="preview.copyright">
              <i class="material-icons">camera_alt</i> Taken by
              <a :href="preview.copyright.link || null">{{
                preview.copyright.name
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      slickOpt: {
        slideToShow: 1,
      },
      gallery: [
        {
          className: "col-md-12",
          items: [



            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKA_2204.jpg?updatedAt=1633155530034",
              transition: "fade-down-right",
              class: "col-12 col-md-4 order-md-12"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_4058.jpg?updatedAt=1633155537292",
              transition: "fade-down-right",
              class: "col-12 col-md-4 order-12"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_3983.jpg?updatedAt=1633155535657",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },

            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKA_2041.jpg?updatedAt=1633155527861",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },

            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKA_2078.jpg?updatedAt=1633155528388",
              transition: "fade-down-right",
              class: "col-12 col-md-4"
            },


            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKA_2086.jpg?updatedAt=1633155529257",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },

            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_3874.jpg?updatedAt=1633155531980",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_3847.jpg?updatedAt=1633155531096",
              transition: "fade-down-right",
              class: "col-12 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_3909.jpg?updatedAt=1633155533906",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_3916.jpg?updatedAt=1633155534798",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_4137.jpg?updatedAt=1633155540187",
              transition: "fade-down-right",
              class: "col-12 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_4075.jpg?updatedAt=1633155539209",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },
            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_4071.jpg?updatedAt=1633155538274",
              transition: "fade-down-right",
              class: "col-6 col-md-4"
            },

            {
              full:
                "https://ik.imagekit.io/aryraditya/ceremonyku/arya-resdipa/SKF_4029.jpg?updatedAt=1633155536479",
              transition: "fade-down-right",
              class: "col-12 col-md-4"
            },
          ],
        },
      ],
      preview: null,
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (this.preview) {
        if (e.key === "ArrowRight") {
          this.next();
        }
        if (e.key === "ArrowLeft") {
          this.prev();
        }
        if (e.key === "Escape") {
          this.closePopup();
        }
      }
    });
  },
  methods: {
    showPopup(image) {
      this.preview = image;
    },
    closePopup() {
      this.preview = null;
    },
    next() {
      this.preview = this.nextImage;
    },
    prev() {
      this.preview = this.prevImage;
    },
  },
  computed: {
    images() {
      const images = [];

      this.gallery.forEach((items) => {
        images.push(...items.items);
      });

      return images;
    },
    currentIndex() {
      return this.preview.full
        ? this.images.map((item) => item.full).indexOf(this.preview.full)
        : null;
    },
    nextImage() {
      let index = this.currentIndex + 1;
      if (index > this.images.length - 1) {
        index = 0;
      }
      return this.currentIndex !== null ? this.images[index] : null;
    },
    prevImage() {
      let index = this.currentIndex - 1;
      if (index < 0) {
        index = this.images.length - 1;
      }
      return this.currentIndex !== null ? this.images[index] : null;
    },
  },
  watch: {
    preview() {
      if (this.preview === null) {
        document.querySelector("body").style.overflowY = null;
      } else {
        document.querySelector("body").style.overflowY = "hidden";
      }
    },
  },
};
</script>

<style type="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
